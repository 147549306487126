import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { onUpdateActiveTemplate } from "../../reducers/activeTemplateReducer";
import Container from "../Container/Container";
import PreconfirmationStep from "./PreconfirmationStep/PreconfirmationStep";
import { LoadingCircle } from "@almafintech/react-components/LoadingCircle";
import juridicalTemplate from "../../templates/allaria/juridical";
import physicalTemplate from "../../templates/allaria/physical";
import useWindowWidth from "../../hooks/useWindowWidth";

import torosCapitalLogo from "../../assets/images/logos/toros-capital.png";
import winaLogo from "../../assets/images/logos/wina.svg";
import styles from "./PreconfirmationCard.module.scss";
import { getCompanyData, isToros, isWina } from "../../utils";

const PreconfirmationCard = () => {
  const {
    container,
    header,
    stepsContainer,
    loading,
    torosLogo,
    winaLogoStyles,
    companyLogo,
    logosContainer,
  } = styles;

  const { logo, logoClassname, companyName } = getCompanyData();

  const { formValues } = useAppSelector(({ onboardingData }) => onboardingData);

  const { preconfirmation, onboardingType } = useAppSelector(
    ({ activeTemplate }) => activeTemplate
  );

  const { steps } = preconfirmation;

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const templateURL = searchParams.get("template");

  const [isLoading, setIsLoading] = useState(true);

  const loadTemplate = async () => {
    if (templateURL === "PHYSICAL") {
      const template = await physicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (templateURL === "JURIDICAL") {
      dispatch(onUpdateActiveTemplate(juridicalTemplate));
    }
  };

  useEffect(() => {
    if (!onboardingType) loadTemplate();
  }, []);

  useEffect(() => {
    if (onboardingType && formValues) setIsLoading(false);
  }, [onboardingType, formValues]);

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  return (
    <Container className={container}>
      <div className={header}>
        <div>
          <h1>Preconfirmación de apertura</h1>
          <p>Revisá que los datos sean correctos.</p>
        </div>
        <div className={logosContainer}>
          {!isMobile && isToros(formValues) && (
            <img
              className={torosLogo}
              src={torosCapitalLogo}
              alt="Toros Capital logo"
            />
          )}
          {!isMobile && isWina(formValues) && (
            <img className={winaLogoStyles} src={winaLogo} alt="Wina logo" />
          )}
          {!isMobile && (
            <img
              className={`${companyLogo} ${
                logoClassname ? styles[logoClassname] : ""
              }`}
              src={logo}
              alt={`${companyName} logo`}
            />
          )}
        </div>
      </div>
      {isLoading ? (
        <div className={loading}>
          <LoadingCircle width="50" />
        </div>
      ) : (
        <div className={stepsContainer}>
          {steps?.map(({ name, columns, fields, editStep }, index) => (
            <PreconfirmationStep
              key={index}
              name={name}
              columns={columns}
              fields={fields}
              editStep={editStep}
            />
          ))}
        </div>
      )}
    </Container>
  );
};

export default PreconfirmationCard;
