import styles from "./SuccessPage.module.scss";
import successIcon from "../../assets/images/icons/ui/ui-icon-success.svg";
import Container from "../Container/Container";
import Layout from "../Layout/Layout";
import { getCompanyData } from "../../utils";

import torosCapitalLogo from "../../assets/images/logos/toros-capital.png";
import winaLogo from "../../assets/images/logos/wina.svg";
import { useSearchParams } from "react-router-dom";

const SuccessPage = () => {
  const { successContainer, body, logosContainer, torosLogo, companyLogo } =
    styles;

  const [searchParams] = useSearchParams();
  const isToros = searchParams.get("toros");
  const isWina = searchParams.get("wina");

  const { logo, logoClassname, companyName } = getCompanyData();

  return (
    <Layout hideLogo hideSupportButton>
      <Container className={successContainer}>
        <div className={logosContainer}>
          {isToros && (
            <img
              className={torosLogo}
              src={torosCapitalLogo}
              alt="Toros Capital logo"
              width={160}
            />
          )}
          {isWina && (
            <img
              className={companyLogo}
              src={winaLogo}
              alt="Wina logo"
              width={160}
            />
          )}
          <img
            className={`${companyLogo} ${
              logoClassname ? styles[logoClassname] : ""
            }`}
            src={logo}
            alt={`${companyName} logo`}
            width={160}
          />
        </div>
        <div className={body}>
          <img src={successIcon} alt="success" />
          <h1>¡Terminaste el registro!</h1>
          <p>
            Procesaremos tu solicitud y recibirás un mail a la brevedad con las
            instrucciones para acceder a tu cuenta.
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default SuccessPage;
