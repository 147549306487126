import { LegacyRef, useEffect, useState, useRef, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useLocation, useSearchParams } from "react-router-dom";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { LivenessData } from "./interfaces/types";
import { livenessTexts, saveLivenessData } from "./utils/utils";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import QRCode from "qrcode";
import { Player } from "@lottiefiles/react-lottie-player";
import lottieJson from "./success-lottie.json";
import { LoadingCircle } from "@almafintech/react-components/LoadingCircle";
import { Button } from "@almafintech/react-components/Button";
import {
  detectFaceLiveness,
  getLivenessSessionId,
  sendIndividualDocument,
  verifyFacialRecognition,
} from "../../connectors/connectors";
import {
  ComponentProps,
  ErrorResponse,
  ValidationErrorResponse,
} from "../../types";
import { closeModal, openModal } from "../../reducers/modalReducer";
import { updateToken } from "../../reducers/authReducer";
import { onUpdateActiveTemplate } from "../../reducers/activeTemplateReducer";
import {
  onUpdateLivenessState,
  onUpdateSessionId,
} from "../../reducers/livenessReducer";
import {
  onUpdatePrimaryButton,
  onUpdateSecondaryButton,
  onUpdateTertiaryButton,
} from "../../reducers/buttonsReducer";
import {
  onUpdateOnClose,
  onUpdateQrImage,
  onUpdateScannedQr,
  onUpdateSuccessMessage,
} from "../../reducers/qrReducer";
import { addOneValidation } from "../../reducers/validationsReducer";
import juridicalTemplate from "../../templates/allaria/juridical";
import physicalTemplate from "../../templates/allaria/physical";
import MinimumRequirementsModal from "./MinimumRequirementsModal/MinimumRequirementsModal";
import { getCompanyData, qrStyle } from "../../utils";
import useWindowWidth from "../../hooks/useWindowWidth";

import errorIcon from "../../assets/images/icons/alert/error-cross.svg";
import successIcon from "../../assets/images/icons/alert/success.svg";
import successQRIcon from "../../assets/images/close-window.svg";
import warningIcon from "../../assets/images/icons/alert/warning.svg";
import validExample from "../../assets/images/liveness/valid-example.svg";
import invalidExample from "../../assets/images/liveness/invalid-example.svg";

import { logAnalyticsAndHotjarEvent, persistor } from "../../main";

import styles from "./LivenessValidationCard.module.scss";
import useForm from "../../hooks/useForm";

const LivenessValidationCard = ({ formik }: ComponentProps) => {
  const {
    livenessContainer,
    livenessMobileContainer,
    initialContainer,
    examplesContainer,
    validContainer,
    invalidContainer,
    success,
    successQR,
    loading,
    error: errorStyle,
    warning,
    contentCenter,
    icon,
    button,
  } = styles;

  const { handleBack, handleContinue } = useForm(formik);

  const [error, setError] = useState<string | null>(null);

  const playerRef: LegacyRef<Player> | undefined = useRef(null);

  const dispatch = useAppDispatch();

  const { livenessState, sessionId } = useAppSelector(
    (state) => state.liveness
  );

  const { formValues, draftUUID } = useAppSelector(
    (state) => state.onboardingData
  );

  const { token: tokenStore } = useAppSelector((state) => state.authentication);

  const { onboardingType, favicon } = useAppSelector(
    (state) => state.activeTemplate
  );

  //Firestore
  const db = getFirestore();
  const attemptsLimit = 5;

  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [livenessData, setLivenessData] = useState<LivenessData>({
    dni: formValues?.["identityDocument"],
    gender: formValues?.["gender"],
    email: formValues?.["email"],
    attemptsDesktop: 0,
    attemptsMobile: 0,
    scannedQR: false,
    isValid: false,
    faceLivenessDetection: "",
    facialRecognition: "",
  });

  const { dni, gender, attemptsDesktop, attemptsMobile, scannedQR, isValid } =
    livenessData;

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const urlUUID = searchParams.get("uuid");
  const urlToken = searchParams.get("token");
  const urlTemplate = searchParams.get("template");
  const continueWithQR = location.pathname.includes("prueba-de-vida");

  const uuid = urlUUID || draftUUID;

  const reachedAttemptsLimit =
    (continueWithQR ? attemptsMobile : attemptsDesktop) >= attemptsLimit;

  const handleRequirementsClick = () => {
    dispatch(openModal({ name: "modal-minimum-requirements" }));
  };

  const handleAnalysisComplete = async () => {
    if (sessionId) {
      try {
        const livenessResult = await detectFaceLiveness({ sessionId });
        const { status, validations } = livenessResult.data;
        const { referenceImage } = validations[0].information;

        // Endpoint receives either "F" or "M", there's no "X"
        const renaperGender = gender === "F" ? gender : "M";

        if (status === "OK") {
          const renaperVerificationData = await verifyFacialRecognition({
            image: referenceImage,
            gender: renaperGender,
            number: dni,
          });

          const { validations: renaperValidations } =
            renaperVerificationData.data;
          const { status: renaperStatus } = renaperValidations[0].information;

          if (renaperStatus === "HIT") {
            const url = `data:image/jpeg;base64,${referenceImage}`;
            const response = await fetch(url);
            const blob = await response.blob();
            const selfie = new File([blob], "selfie.jpg", {
              type: "image/jpeg",
            });

            await sendIndividualDocument({
              file: selfie,
              documentType: "SELFIE",
              draftUUID: uuid,
              // cuit: formValues?.cuit,
            });

            //FACIAL_RECOGNITION
            dispatch(
              addOneValidation({
                name: renaperValidations[0].name,
                externalCode: renaperValidations[0].externalCode,
              })
            );

            //FACE_LIVENESS_DETECTION
            dispatch(
              addOneValidation({
                name: validations[0].name,
                externalCode: validations[0].externalCode,
              })
            );

            saveLivenessData({
              ...livenessData,
              isValid: true,
              uuid,
              facialRecognition: renaperValidations[0].externalCode,
              faceLivenessDetection: validations[0].externalCode,
            });
          } else {
            handleError();
          }
        } else {
          // status is "CHECK" or "FAILED"
          handleError();
        }
      } catch (error) {
        console.log(error);
        console.log("Error in liveness validation");
        handleError();
      }
    }
  };

  const handleError = (error?: string) => {
    setError(error ?? "FAILED_VALIDATION");
    dispatch(onUpdateLivenessState("NOT_VALIDATED"));
  };

  const getErrorData = () => {
    let errorType: "WARNING" | "ERROR" = "ERROR";
    let errorMessage: ReactNode;

    if (reachedAttemptsLimit) {
      errorType = "ERROR";
      errorMessage = (
        <span>
          Lo sentimos, no pudimos reconocerte.
          <br></br>
          <a
            href={`https://wa.me/${getCompanyData().phone}`}
            rel="noreferrer"
            target="_blank"
          >
            Contactá a soporte{" "}
          </a>
          para continuar.
        </span>
      );
      return { errorType, errorMessage };
    }

    switch (error) {
      case "CAMERA_FRAMERATE_ERROR":
        errorType = "WARNING";
        errorMessage = (
          <span>
            Tu cámara o buscador de internet no cumple los{" "}
            <p onClick={handleRequirementsClick}>requisitos mínimos</p>
          </span>
        );
        break;
      case "CONNECTION_TIMEOUT":
        errorType = "WARNING";
        errorMessage = <span>Buscá un lugar con mejor conexión.</span>;
        break;
      case "TIMEOUT":
        errorMessage = (
          <span>
            Lo sentimos, no pudimos reconocerte. Rellená el óvalo con tu cara.
          </span>
        );
        break;
      case "FACE_DISTANCE_ERROR":
        errorMessage = (
          <span>
            Lo sentimos, no pudimos reconocerte. Asegurate de no acercarte
            durante la captura.
          </span>
        );
        break;
      default:
        errorMessage = (
          <span>
            Lo sentimos, no pudimos reconocerte.
            <br></br>
            Por favor, intentalo nuevamente.
          </span>
        );
    }

    return { errorType, errorMessage };
  };

  const { errorType, errorMessage } = getErrorData();

  const dimensions = useWindowWidth();
  const isMobile = dimensions.width < 768;

  const loadTemplate = async () => {
    logAnalyticsAndHotjarEvent("qr_code_scanned_selfie");
    if (urlTemplate === "PHYSICAL") {
      const template = await physicalTemplate();
      dispatch(onUpdateActiveTemplate(template));
    }

    if (urlTemplate === "JURIDICAL") {
      dispatch(onUpdateActiveTemplate(juridicalTemplate));
    }
  };

  // Get template to load favicon in mobile flow
  useEffect(() => {
    if (!onboardingType) loadTemplate();
  }, []);

  useEffect(() => {
    if (favicon) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.type = "image/png";
      newLink.href = favicon;
      document.head.appendChild(newLink);
    }
  }, [favicon]);

  useEffect(() => {
    if (urlToken) dispatch(updateToken(urlToken));
  }, [urlToken]);

  useEffect(() => {
    if (isValid && livenessState !== "VALIDATED") {
      dispatch(closeModal());
      saveLivenessData({
        ...livenessData,
        scannedQR: false,
        uuid,
      });
      logAnalyticsAndHotjarEvent("step_identity_completed_pf");
      logAnalyticsAndHotjarEvent("identity_verification_completed");

      dispatch(onUpdateLivenessState(continueWithQR ? "VALIDATED" : null));
      setTimeout(() => {
        playerRef.current?.play();
      }, 1000);
    }
  }, [isValid, livenessState]);

  useEffect(() => {
    error &&
      (error === "CAMERA_ACCESS_ERROR"
        ? dispatch(onUpdateLivenessState("CAMERA_ACCESS_REQUIRED"))
        : handleError());
  }, [error]);

  useEffect(() => {
    if (error && !isLoadingData) {
      const updatedAttempts = continueWithQR
        ? {
            attemptsMobile: attemptsMobile + 1,
          }
        : {
            attemptsDesktop: attemptsDesktop + 1,
          };
      saveLivenessData({
        ...livenessData,
        ...updatedAttempts,
        uuid,
      });
    }
  }, [error, isLoadingData]);

  useEffect(() => {
    if (livenessState && !continueWithQR) {
      if (["INITIAL", "NOT_VALIDATED"].includes(livenessState)) {
        if (!isMobile) {
          dispatch(
            onUpdateSecondaryButton({
              text: "Validar con mi teléfono",
              onClick: () => {
                setError(null);
                saveLivenessData({
                  ...livenessData,
                  scannedQR: false,
                  uuid,
                });
                dispatch(onUpdateScannedQr(false));
                dispatch(openModal({ name: "modal-qr" }));
              },
              disabled: false,
              show: true,
            })
          );
        } else {
          dispatch(
            onUpdateSecondaryButton({
              text: "Volver",
              disabled: false,
              isLoading: false,
              onClick: handleBack,
              show: true,
            })
          );
          onUpdateLivenessState(null);
        }
      }

      if (isMobile) {
        dispatch(
          onUpdateTertiaryButton({
            show: false,
          })
        );
      } else {
        dispatch(
          onUpdateTertiaryButton({
            text: "Volver",
            disabled: false,
            isLoading: false,
            onClick: handleBack,
            show: true,
          })
        );
        onUpdateLivenessState(null);
      }

      switch (livenessState) {
        case "INITIAL":
          dispatch(
            onUpdatePrimaryButton({
              text: "Comenzar captura",
              disabled: false,
              isLoading: !sessionId,
              onClick: () => dispatch(onUpdateLivenessState("DETECTOR")),
              show: true,
            })
          );
          break;

        case "DETECTOR":
          dispatch(
            onUpdatePrimaryButton({
              disabled: true,
              show: true,
            })
          );
          break;

        case "NOT_VALIDATED":
          dispatch(
            onUpdatePrimaryButton({
              text: "Reintentar captura",
              isLoading: false,
              onClick: () => {
                setError(null);
                dispatch(onUpdateSessionId(""));
                dispatch(onUpdateLivenessState("INITIAL"));
              },
              disabled: false,
              show: true,
            })
          );

          reachedAttemptsLimit &&
            dispatch(
              onUpdatePrimaryButton({
                disabled: true,
                show: true,
              })
            );
          break;

        case "VALIDATED":
          dispatch(
            onUpdatePrimaryButton({
              text: "Continuar",
              disabled: false,
              isLoading: false,
              type: "button",
              onClick: handleContinue,
              show: true,
            })
          );
          dispatch(
            onUpdateSecondaryButton({
              text: "Volver",
              disabled: false,
              isLoading: false,
              onClick: handleBack,
              show: true,
            })
          );

          dispatch(
            onUpdateTertiaryButton({
              show: false,
            })
          );

          onUpdateLivenessState(null);
          break;
        default:
          break;
      }
    }
  }, [livenessState, sessionId, reachedAttemptsLimit, isMobile]);

  useEffect(() => {
    livenessState === "INITIAL" &&
      (continueWithQR || !sessionId) &&
      getLivenessSessionId()
        .then((res) => {
          const sessionId = res.data.sessionId;
          dispatch(onUpdateSessionId(sessionId));
        })
        .catch((error: ErrorResponse | ValidationErrorResponse) => {
          console.log(error);
          handleError("SESSION_ID_ERROR");
        });
    if (livenessState === "VALIDATED") {
      dispatch(
        onUpdatePrimaryButton({
          text: "Continuar",
          disabled: false,
          isLoading: false,
          type: "button",
          onClick: handleContinue,
          show: true,
        })
      );
    }
  }, [livenessState]);

  useEffect(() => {
    if (!isLoadingData && !livenessState && !isValid) {
      dispatch(
        onUpdateLivenessState(
          reachedAttemptsLimit ? "NOT_VALIDATED" : "INITIAL"
        )
      );
    }
  }, [isLoadingData, reachedAttemptsLimit, livenessState]);

  useEffect(() => {
    const firestoreData = { ...livenessData, uuid };
    //From form
    const { dni, gender, email } = livenessData;

    const unsub = onSnapshot(doc(db, "livenessValidation", uuid), (doc) => {
      if (doc.exists()) {
        const data = doc.data() as LivenessData;
        if (
          (!gender && !dni) ||
          (data.gender === gender && data.dni === dni && data.email)
        ) {
          setLivenessData(data);
          setIsLoadingData(false);
        } else {
          saveLivenessData({
            ...data,
            dni,
            gender,
            email,
            uuid,
          });
        }
        if (
          data.faceLivenessDetection !== "" &&
          data.facialRecognition !== ""
        ) {
          dispatch(
            addOneValidation({
              name: "FACE_LIVENESS_DETECTION",
              externalCode: data.faceLivenessDetection,
            })
          );
          dispatch(
            addOneValidation({
              name: "FACIAL_RECOGNITION",
              externalCode: data.facialRecognition,
            })
          );
        }
      } else {
        saveLivenessData(firestoreData);
      }
    });
    return unsub;
  }, []);

  useEffect(() => {
    if (continueWithQR && !isLoadingData) {
      saveLivenessData({
        ...livenessData,
        scannedQR: true,
        uuid,
      });
      localStorage.clear();
    }
  }, [continueWithQR, isLoadingData]);

  // For QR modal
  useEffect(() => {
    QRCode.toDataURL(
      `${window.location.origin}/prueba-de-vida?uuid=${draftUUID}&token=${tokenStore}&template=${onboardingType}`,
      qrStyle
    ).then((img) => dispatch(onUpdateQrImage(img)));

    dispatch(
      onUpdateSuccessMessage("Ya podés validar tu identidad desde el teléfono.")
    );

    dispatch(
      onUpdateOnClose(() =>
        saveLivenessData({
          ...livenessData,
          scannedQR: false,
          uuid,
        })
      )
    );
  }, []);

  useEffect(() => {
    dispatch(onUpdateScannedQr(scannedQR));
  }, [scannedQR]);

  useEffect(() => {
    if (continueWithQR) {
      // Reset redux persist
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });
      localStorage.clear();
    }
  }, [continueWithQR]);

  return (
    <div
      className={`${
        continueWithQR ? livenessMobileContainer : ""
      } ${livenessContainer}`}
    >
      <MinimumRequirementsModal />
      {isLoadingData && !livenessState && (
        <div className={loading}>
          <LoadingCircle width="50" />
        </div>
      )}
      {livenessState === "INITIAL" && (
        <section className={initialContainer}>
          <div>
            <h3>A continuación debés validar tu identidad</h3>
            <span>
              Acercate a un lugar con buena iluminación, sacate cualquier
              accesorio facial y subí el brillo de tu dispositivo.
            </span>
          </div>
          <div className={examplesContainer}>
            <div className={validContainer}>
              <img src={successIcon} alt="successIcon" className={icon} />
              <img src={validExample} alt="Ejemplo válido" />
            </div>
            <div className={invalidContainer}>
              <img src={errorIcon} alt="errorIcon" className={icon} />
              <img src={invalidExample} alt="Ejemplo inválido" />
            </div>
          </div>
        </section>
      )}
      {sessionId && livenessState === "DETECTOR" && (
        <FaceLivenessDetector
          sessionId={sessionId}
          region="us-east-1"
          onAnalysisComplete={handleAnalysisComplete}
          disableStartScreen={true}
          onError={(error) => {
            console.log(error);
            handleError(error.state);
          }}
          displayText={livenessTexts}
        />
      )}
      {livenessState === "CAMERA_ACCESS_REQUIRED" && (
        <div className={`${contentCenter} ${warning}`}>
          <img src={warningIcon} alt="icon" />
          <span>Conectá una cámara para validar tu identidad</span>
        </div>
      )}
      {isValid && !livenessState && (
        <div className={`${contentCenter}`}>
          <Player
            onEvent={(event) => {
              if (event === "complete" || event === "error")
                dispatch(onUpdateLivenessState("VALIDATED"));
            }}
            ref={playerRef}
            src={lottieJson}
            loop={false}
            style={{
              width: "75%",
              padding: "3rem",
            }}
          />
        </div>
      )}
      {livenessState === "VALIDATED" && (
        <section
          className={`${contentCenter} ${!continueWithQR ? success : ""}`}
        >
          {continueWithQR ? (
            <div className={successQR}>
              <img src={successQRIcon} alt="successQRIcon" />
              <h2>
                ¡Listo! Podés cerrar esta página y seguir en tu computadora.
              </h2>
            </div>
          ) : (
            <div className={contentCenter}>
              <img src={successIcon} alt="successIcon" />
              <span>
                ¡Validaste tu identidad!
                <br />
                Podés continuar.
              </span>
            </div>
          )}
        </section>
      )}
      {livenessState === "NOT_VALIDATED" && (
        <div
          className={`${contentCenter} ${
            errorType === "ERROR" ? errorStyle : warning
          } ${scannedQR ? "mb-5" : ""}`}
        >
          <img
            src={errorType === "ERROR" ? errorIcon : warningIcon}
            alt="icon"
          />
          {errorMessage}
        </div>
      )}
      {continueWithQR &&
        !reachedAttemptsLimit &&
        !["VALIDATED", null].includes(livenessState) && (
          <Button
            text={
              livenessState === "NOT_VALIDATED"
                ? "Reintentar captura"
                : "Comenzar captura"
            }
            type="button"
            className={button}
            isLoading={!sessionId}
            disabled={
              error === "CAMERA_FRAMERATE_ERROR" ||
              (!!livenessState && livenessState === "DETECTOR")
            }
            onClick={() => {
              if (livenessState !== "NOT_VALIDATED")
                logAnalyticsAndHotjarEvent("identity_verification_started");

              setError(null);
              livenessState === "NOT_VALIDATED" &&
                dispatch(onUpdateSessionId(""));
              dispatch(
                onUpdateLivenessState(
                  livenessState === "INITIAL" ? "DETECTOR" : "INITIAL"
                )
              );
            }}
          />
        )}
    </div>
  );
};

export default LivenessValidationCard;
