import axios from "axios";
import {
  PersonType,
  DocumentType,
  AuthToken,
  DraftUUID,
  Email,
  StepEndpoint,
  GenderInitials,
  RegenToken,
  CompanyType,
} from "../types";
import { store } from "../app/store";

const BASE_URL = import.meta.env.VITE_BASE_URL;
const BASE_URL_PITBULL = import.meta.env.VITE_BASE_URL_PITBULL;
const company = import.meta.env.VITE_COMPANY as CompanyType;

//Creating an instance of axios to use endpoints with token
const axiosWithToken = axios.create({
  baseURL: BASE_URL,
});

axiosWithToken.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.authentication.token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
//AUTHENTICATION CONTROLLER
export const authEmail = ({
  email,
  companyType,
}: {
  email: string;
  companyType: CompanyType;
}) => {
  return axios.post(`${BASE_URL}/auth/request?companyType=${companyType}`, {
    email,
  });
};

export const authToken = ({ signatureRequestId, email, token }: AuthToken) => {
  return axios.post(
    `${BASE_URL}/auth/token`,
    {
      signatureRequestId,
      email,
    },
    { headers: { "X-Token": token } }
  );
};

export const regenerateToken = ({
  signatureRequestId,
  email,
  companyType,
}: RegenToken) => {
  return axios.post(`${BASE_URL}/auth/regenerate?companyType=${companyType}`, {
    signatureRequestId,
    email,
  });
};

//DRAFTS
//Draft uid we get from the response of the authToken (jwt).
export const getDraft = ({ draftUUID }: { draftUUID: DraftUUID }) => {
  return axiosWithToken.get(`${BASE_URL}/drafts/${draftUUID}`, {});
};

export const getDrafts = ({
  cuit,
  personType,
}: {
  cuit: string;
  personType: PersonType;
}) => {
  return axiosWithToken.get(
    `${BASE_URL}/drafts?cuit=${cuit}&personType=${personType}`
  );
};

export const getIndividualDrafts = ({
  draftUUID,
  documentType,
}: {
  draftUUID: DraftUUID;
  documentType: DocumentType;
}) => {
  return axiosWithToken.get(
    `${BASE_URL}/individual-drafts/${draftUUID}/documents/${documentType}`
  );
};

interface CreateDraftProps {
  stepId: number;
  body: {
    companyType: string;
    personType: PersonType;
    email: Email;
    steps: StepEndpoint[];
  };
}

export const sendStep = ({ stepId, body }: CreateDraftProps) => {
  return axiosWithToken.post(`${BASE_URL}/steps/${stepId}`, {
    ...body,
  });
};

export const completeDraft = ({ draftUUID }: { draftUUID: DraftUUID }) => {
  return axiosWithToken.post(`${BASE_URL}/drafts/${draftUUID}/complete`);
};

export const deleteIndividualDraft = ({
  draftUUID,
}: {
  draftUUID: DraftUUID;
}) => {
  return axiosWithToken.delete(`${BASE_URL}/individual-drafts/${draftUUID}`);
};

export const sendIndividualDocument = ({
  draftUUID,
  documentType,
  file,
}: // cuit,
{
  draftUUID: DraftUUID;
  documentType: DocumentType;
  // cuit: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any;
}) => {
  const formdata = new FormData();
  formdata.append("file", file);

  return axiosWithToken.post(
    `${BASE_URL}/individual-drafts/${draftUUID}/documents?documentType=${documentType}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  // This will be used in future versions
  // return axiosWithToken.post(
  //   `${BASE_URL}/individual-drafts/${draftUUID}/owners/${cuit}/documents/?documentType=${documentType}`,
  //   formdata,
  //   {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //   }
  // );
};

//VALIDATION CONTROLLER
export const getLivenessSessionId = () => {
  return axiosWithToken.post(`${BASE_URL}/validations/liveness-session`);
};

export const verifyFacialRecognition = ({
  number,
  gender,
  image,
}: {
  number: string;
  gender: GenderInitials;
  image: string;
}) => {
  return axiosWithToken.post(
    `${BASE_URL}/validations/facial-recognition?company=${company}`,
    {
      number,
      gender,
      image,
    }
  );
};

export const detectFaceLiveness = ({ sessionId }: { sessionId: string }) => {
  return axiosWithToken.post(
    `${BASE_URL}/validations/face-liveness-detection?company=${company}`,
    {
      sessionId,
    }
  );
};

export const validateRenaper = ({
  number,
  order,
  gender,
}: {
  number: string;
  order: string;
  gender: GenderInitials;
}) => {
  return axiosWithToken.post(
    `${BASE_URL}/validations/renaper?company=${company}`,
    {
      number,
      order,
      gender,
    }
  );
};

//ACTIVITIES
export const getPhysicalActivities = () => {
  // return axios.get(`${BASE_URL}/physical-activities`);
  return axios.get(`${BASE_URL_PITBULL}/activities/physical`);
};

export const getJuridicalActivities = () => {
  return axiosWithToken.get(`${BASE_URL}/juridical-activities`);
};

//QUESTIONS
export const getIndividualQuestions = () => {
  return axiosWithToken.get(`${BASE_URL}/individual-questions`);
};

export const getBusinessQuestions = ({ company }: { company: CompanyType }) => {
  return axios.get(`${BASE_URL}/business-questions?company${company}`);
};

// COUNTRIES

export const getCountryList = () => {
  return axios.get(`${BASE_URL_PITBULL}/countries`);
};
